.wallet-widget-button {
  margin-right: 15px;
  color: black;
  background-color: transparent;
  border: none;
}
.wallet-widget-button:hover {
  background-color: #0ab44e;
}

.wallet-widget-icon {
  align-self: center;
  color: #6c757d;
  font-size: 20px;
}

.wallet-widget-icon:hover {
  color: white;
}
