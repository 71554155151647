@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,900&display=swap');

$primary-color: #0ab44e;
$secondary-color: #f8b545;

body{
  font-family: 'Montserrat', sans-serif !important;
}
.wazieats-bg-primary-color {
  background-image: url('bg.png');
  background-color: $primary-color;
}

.swal-button--confirm{
  color: white;
  background-color: $primary-color;
  border: 1px solid $primary-color;
  min-width: 150px !important;

  &:hover{
    background-color: white !important;
    color: $primary-color;
    border: 1px solid $primary-color;
  }
}

.swal-button--cancel{
  min-width: 150px !important;
  background-color: white !important;
  color: $primary-color;
  border: 1px solid $primary-color;

  &:hover{
    color: white;
    background-color: $primary-color !important;
    border: 1px solid $primary-color;
  }
}

.swal-footer {
  border-top: 1px solid #E9EEF1;
  overflow: hidden;
}

.btn-login, .btn-login:hover, .btn-login:active, .btn-login:focus {
  font-size: 1.1rem;
  background-color: $primary-color;
  color: white;
  border-color: $primary-color;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-login-alt2, .btn-login-alt2:hover, .btn-login-alt2:active, .btn-login-alt2:focus {
  font-size: 1.1rem;
  background-color: #444;
  color: white;
  border-color: #444;
}

.btn-login-alt, .btn-login-alt:hover, .btn-login-alt:active, .btn-login-alt:focus {
  font-size: 1.1rem;
  color: $primary-color;
  background-color: white;
  border-color: $primary-color;
}

.btn-login2, .btn-login2:hover, .btn-login2:active, .btn-login2:focus {
  font-size: 1.1rem;
  background-color: $secondary-color;
  color: white;
  border-color: $secondary-color;
}

.product-bg {
  background: radial-gradient(farthest-corner at 40px 40px, #fff, #eee) !important;
  box-shadow: -3px 3px 5px #ccc;
}

.wazieats-color {
  color: $primary-color;
}

.wazieats-2-color, .wazieats-2-link {
  color: $secondary-color;
}

.wazieats-2-link:hover, .wazieats-2-link:active {
  color: $primary-color;
}

.wazi-img-list {
  border: 2px solid $primary-color;
  border-radius: 5px;
}

.wazi-btn {
  color: white;
  background-color: $primary-color !important;
  border-color: $primary-color !important;

  &:hover, &:active, &:focus {
    color: white;
    border-color: $primary-color !important;
    background-color: $primary-color !important;
  }
}

.wazi-btn-add {
  @include border-radius(50px);
  width: 45px;
  height: 45px;
  font-size: 1.8rem;
}

.wazi-modal {
  .modal-header {
    color: white;
    background: $primary-color;
  }
}

.wazi-modal-confirm {
  .modal-header, .modal-body, .modal-footer {
    justify-content: center;
  }
  .modal-header {
    background: white;
  }
}

.wazi-card-item {
  position: relative;
  border-radius: 10px;

  .avatar-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .btn-actions-pane-right {
    z-index: 7;
  }
}

.actions-icon-btn .btn-icon-only:hover, .wazi-card-item:hover .btn-icon-only {
  color: white;
}

.wazi-card-item .item-detail {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 6;
  bottom: 0;
  right: 0;
  background: $primary-color;
  opacity: 0;
  border-radius: 10px;
  transition: opacity .3s, visibility .3s;
}

.wazi-card-item .item-detail h5{
  text-align: center;
  margin-top: 10px;
}

.wazi-card-item:hover .item-detail {
  opacity: 0.6;
}

.wazi-dropzone {
  width: 150px;
  border: #ced4da dashed 2px;
  border-radius: 0.3rem;
  display: flex;
  margin-left: 13px;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 150px;
  transition: all 0.2s;

  &:hover {
    border-color: $primary-color;
  }
}
.wazi-dropzone3 {
  width: 300px;
  min-height: 300px;
  border: #ced4da dashed 2px;
  border-radius: .3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  transition: all .2s;

  &:hover {
    border-color: $primary-color;
  }
}

//.without_ampm::-webkit-datetime-edit-ampm-field {
//  display: none;
//}
input[type=time]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance:none;
  appearance: none;
  margin: -10px;
}

.wazi-eats-dropzone {
  width: 450px;
  height: 20px;
  border: #ced4da dashed 2px;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    border-color: $primary-color;
  }
}

.wd-50 {
  width: 50% !important;
}

@media (max-width: 991px) {
  .wd-50 {
    width: 100% !important;
  }
}

.wazi-dropzone2 {
  width: 200px;
  height: 150px;
  border: #ced4da dashed 2px;
  border-radius: 0.3rem;
  display: flex;
  // margin-left: 13px;
  align-items: center;
  align-content: center;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    border-color: $primary-color;
  }
}

.thumbs-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  .thumbs {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    width: 125px;
    height: 125px;
    padding: 4px;
    box-sizing: border-box;

    .thumbs-inner {
      display: flex;
      min-width: 0;
      overflow: hidden;

      .img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }

}

.thumb-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;

  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;

    .thumb-inner {
      display: flex;
      min-width: 0;
      overflow: hidden;

      .img {
        display: block;
        width: 150px !important;
        height: 150px !important;
      }
    }
  }

}

.filter-container {
  width: 150px;
  z-index: 10;
}

.swal-footer {
  text-align: center !important;
}

.btn-demo {
  margin-top: 25px;
  padding: 10px;
  font-size: 16px;
  background-color: #e2a324;
  border-color: #e2a324;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
}

.btn-terms {
  //margin-top: 25px;
  padding: 0px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background-color: inherit;
  //border-color: #e2a324;
  color: #000;

  display: inline-block;
  //border-radius: 5px;
  //font-weight: 500;
}
.btn-terms:hover {
  background-color: #fff;
  color: #0ab44e;
}

.btn-demo:hover {
  background-color: #fff;
  color: #0ab44e;
}

// Login Css
.main-section-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh !important;
}

.section1-login {
  display: flex;
  justify-content: center;
}

.td {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.show {
  display: flex;
}

.section2-login {
  display: flex;
  justify-content: center;
}

// Vendor Css
.main-section {
  display: flex;
  flex-direction: row;
}

.section1 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.sd {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
// .banner h1 {
//   font-size: 3rem;
// }
.wt {
  display: flex;
}

.section2 {
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cbtn {
  height: 15%;
  width: 20%;
}

.rbtn {
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
  height: 125px;
  width: 200px;
}

.rbtn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
  height: 120px;
  width: 120px;
}
.form-vendor-end {
  margin-top: 85%;
  margin-bottom: 15px;
  align-self: flex-end;
}

@media (max-width:768px) {

  .main-section-login {
    flex-direction: row;
  }
  .section1-login {
    height: 20% !important;
    flex-direction: column;
    justify-content: flex-start;
  }
  .ts {
    flex-direction: row;
    gap: 50px;
  }
  .show {
    display: block;
  }
  .section2-login {
    height: 80% !important;
  }

  .main-section {
    display: flex;
    flex-direction: column;
  }
  .section1 {
    height: 20% !important;
    position: sticky;
  }
  .sd {
    height: 20vh !important;
    width: 100%;
  }
  .banner {
    height: 80% !important;
    width: 100%;
    gap: 10px;
    font-size: 15px;
    align-self: center;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0px;
  }
  .banner span {
    display: none;
  }
  .wt {
    display: none;
  }
  .section2 {
    height: 95% !important;
    position: relative;
    overflow-y: scroll;
  }
  .form-vendor {
    height: auto !important;
  }
  .wh2 {
    font-size: 20px !important;
  }
  .cbtn {
    width: 100%;
  }
  .wazi-eats-dropzone, .wazi-dropzone2 {
    width: auto;
    margin-left: 0px;
  }
  .form-vendor-end {
    margin-top: 5%;
  }
}

.w-2-4{
  width: 50%;
}

.tags {
  display: inline-block;
  padding: 4px 6px; /* Espacement intérieur */
  background-color: #206822; /* Couleur de fond gris */
  color: #fff; /* Couleur du texte gris foncé */
  border-radius: 5px; /* Coins arrondis */
  font-size: 14px; /* Taille de la police */
  margin: 5px; /* Marges autour du tag */
  border: 1px solid #007a04; /* Bordure grise */
  transition: background-color 0.3s; /* Transition pour le changement de couleur */
}

.tags:hover {
  background-color: #b0b0b0; /* Couleur de fond au survol */
  cursor: pointer; /* Curseur pointer au survol */
}
